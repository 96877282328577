@import "../partials/base";
html,body {
    background: #fff;
}
.mobile-logo {
    display: none;
}
.logistics-logo img {
    height: 28px;
}
.logistics-nav {
    display: flex;
    align-items:center;
}
.logistics-nav a.navl {
    margin-left: 22px;
}
.logistics-nav a.nav_tel {
    font-size: 16px;
    font-weight: bold;
    color: #00c4b3;
}
.logistics-nav a.btn {
    margin-left: 22px;
    width: 172px;
    height: 32px;
    line-height: 32px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
div.home-title h2 {
    font-size: 30px;
    letter-spacing: 0;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
    background: #00c4b3;
    color: #fff;
}
.select-ctrl {
    position: relative;
    width: 193px;
    min-height: 46px;
    padding: 0 10px;
    font-size: 15px;
    letter-spacing: .25px;
    font-weight: bold;
    border: 1px solid #e7e7e7;
    background: #fff get_prefix_url("imgs/tvmall/sel.png") no-repeat 95% center;
    cursor: pointer;
    .like-placeholder {
        color: #999;
        line-height: 46px;
    }
    .select {
        appearance: none;
        border: 0 none;
        width: 100%;
        height: 100%;
        outline: none;
        background: transparent;
        font-weight: 900;
        color: #999;
        font-size: 13px;
    }
    .dashboardcode-bsmultiselect {
        width: 100%;
        padding: 0px 0;
        .badge {
            background: #e4e4e4;
            line-height: 12px!important;
            padding: 4px 5px!important;
            border-radius: 3px;
            align-self: center;
            font-size: 12px;
            font-weight: normal;
            color: #333;
            margin: 1px;
            max-width: 90%;
            white-space: inherit;
            display: flex;
            align-items: center;
            text-align: left;
            span {
                vertical-align: middle;
            }
            .close {
                margin-top: 0px;
                vertical-align: middle;
                margin-left: 3px;
                color: #fff;
                font-weight: 400;
                text-shadow: none;
                opacity: 1;
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
    .dropdown-menu {
        transform: translate3d(-2px, 44px, 0px) !important;
        width: 100%;
        max-height: 230px;
        overflow-y: auto;
        .text-primary {
            color: #00c4b3!important;
        }
        .custom-control-input:checked~.custom-control-label::before {
            color: #fff;
            border-color: #00c4b3;
            background-color: #00c4b3;
        }
        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
            background: #00c4b3;
        }
        .custom-control-label {
            font-weight: normal;
            font-size: 14px;
        }
        .custom-checkbox .custom-control-label::before {
            border-radius: 2px;
        }
    }
    .form-control {
        width: 100%;
        height: auto!important;
        border: 0 none !important;
        background: transparent!important;
        box-shadow: none!important;
        // flex-wrap: nowrap!important;
        padding: 0;
        overflow: hidden;
        min-height: 42px;
        &:focus {
            background: transparent;
            border: 0 none;
            box-shadow: none;
        }
        input {
            font-size: 13px;
            font-weight: bold;
            &::placeholder {
                color: #999;
            }
        }
    }
}
.tvmall-banner-wrapper {
    padding-top: 64px;
    .main-info-wrap {
        background: #e9f8f7;
        padding: 0 16px;
        .main-info {
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
            padding: 48px 0;
        }
        .main-left {
            text-align: center;
            flex: 1;
            .main-title {
                font-size: 28px;
                font-weight: bold;
                line-height: 1.3;
                color: #00c4b3;
            }
            .main-desc {
                font-size: 18px;
                line-height: 1.3;
                color: #444;
                margin-top: 20px;
            }
            .main-link {
                margin-top: 14px;
                a {
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: bold;
                    color: #00c4b3;
                    text-decoration: none;
                    border: 2px solid #00c4b3;
                    border-radius: 99em;
                    padding: 10px 18px 10px 30px;
                    display: inline-block;
                    text-transform: uppercase;
                    i.fas {
                        font-size: 28px;
                        line-height: 0;
                        vertical-align: middle;
                        margin-left: 30px;
                    }
                }    
            }
        }
        .main-right {
            width: 530px;
            margin-left: 58px;
            position: relative;
            .main-form-wrap {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: #26aea3;
                box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.35);
                border-radius: 10px;
                padding: 28px 21px 38px;
                .mfw-title {
                    font-size: 20px;
                    line-height: 1.3;
                    font-weight: bold;
                    text-align: center;
                    color: #fff;
                }
                .banner-form {
                    margin: 32px 0 0;
                }
                .input-group {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 13px;
                }
                .input-ctrl {
                    width: 49%;
                }
                .input-select {
                    position: relative;
                    .dropdown-menu {
                        width: 100%;
                        .dropdown-item:hover, .dropdown-item:focus {
                            color: #00c4b3;
                        }
                        .dropdown-item.active, .dropdown-item:active {
                            color: #00c4b3;
                            background: #f8f9fa;  
                        }
                    }
                }
                .txt {
                    width: 100%;
                    min-height: 46px;
                    padding: 0 10px;
                    color: #000;
                    font-size: 13px;
                    font-weight: bold;
                    background: #fff;
                    border: 1px solid #e7e7e7;
                    &::placeholder {
                        color: #999;
                    }
                    &.txt-in-date {
                        background: #fff get_prefix_url("imgs/tvmall/sel.png") no-repeat 95% center;
                    }
                    &.has-arrow {
                        background: #fff get_prefix_url("imgs/tvmall/sel.png") no-repeat 95% center;
                    }
                }
                .button-wrap {
                    padding-top: 13px;
                    text-align: center;
                    .btn {
                        width: 228px;
                        text-transform: uppercase;
                        &[disabled] {
                            background: #e4e4e4;
                            cursor: not-allowed;
                            color: #fff;
                        }
                    }
                    .see-less {
                        display: none;
                    }
                }
            }
        }
    }
    .banner-bg {
        img {
            width: 100%;
            max-height: 300px;
            object-fit: cover;
        }
    }
}
.solution-wrapper {
    padding: 104px 16px 92px;
    .solution-wrap {
        margin: 0 auto;
        width: 100%;
        max-width: 900px;
        padding: 100px 0 0;
    }
    .solu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 80px;

        .solu-img {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 280px;
            }
        }
        .solu-info {
            margin: 0 0 0 60px;
            flex: 1;
            .fannao {
                font-weight: bold;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.5px;
                color: #8E969D;
                padding-left: 26px;
            }
            .fn-box {
                background: #ECEBEB;
                border-radius: 10px;
                position: relative;
                color: #444;
                padding: 22px 21px 23px 26px;
                margin-top: 14px;
                .fn-title {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 24px;
                }
                .fn-content {
                    font-size: 16px;
                    line-height: 1.4;
                    margin-top: 10px;
                }
                .fn-icon {
                    width: 68px;
                    height: 68px;
                    position: absolute;
                    right: 14px;
                    top: -38px;
                    background: #fafafa;
                    border-radius: 99em;
                    img {
                        width: 100%;
                    }
                }
            }
            .fbs-title {
                font-weight: bold;
                font-size: 20px;
                line-height: 1.4;
                color: #26AEA3;
                padding-left: 26px;
                margin-top: 20px;
            }
            .fbs-content {
                font-size: 16px;
                line-height: 1.4;
                letter-spacing: 0.5px;
                color: #444444;
                padding-left: 26px;
                margin-top: 10px;
            }
        }
        &.second-soul-item {
            .solu-img {
                order: 2;
            }
            .solu-info {
                order: 1;
                margin: 0 60px 0 0;
            }
        }
    }
    .solution-btn-wrap {
        text-align: center;
        .btn {
            width: 363px;
            text-transform: uppercase;
        }
    }
}
.faq-wrapper {
    background: #f9f9f9;
    padding: 104px 16px 108px;
    .faq-wrap {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        margin-top: 60px;
    }
    .faq-group {
        display: flex;
        align-items: center;
        .tvmall-en & {
            display: block;
            .faq-tag {
                border-radius: 10px;
                width: 220px;
                margin: 0 auto;
            }
        }
    }
    .faq-list {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
    }
    .faq-tag {
        width: 200px;
        height: 64px;
        line-height: 64px;
        background: #edeff1;
        border-radius: 10px 0px 0px 10px;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        color: #2B4153;
    }
    .faq-item {
        width: 32%;
        display: flex;
        align-items: center;
        height: 400px;
    }
    .faq {
        background: #fff;
        border: 3px solid #fff;
        box-shadow: 0px 0px 10px rgba(51, 51, 51, 0.18);
        border-radius: 10px;
        width: 100%;
        height: 84%;
        transition: height .2s;
        .faq-img {
            text-align: center;
            padding-top: 50px;
            img {
                height: 80px;
            }
        }
        .faq-box {
            margin-top: 30px;
            padding: 0 8px;
            .faq-question {
                font-weight: bold;
                font-size: 16px;
                line-height: 1.3;
                text-align: center;
                color: #344A5E;
            }
            .faq-answer {
                font-size: 15px;
                line-height: 1.4;
                text-align: center;
                color: #000000;
                margin-top: 10px;
                display: none;
                text-align: justify;
                opacity: 0;
                transition: opacity .4s;
            }
        }
        &:hover, &.cur {
            height: 100%;
            border: 3px solid #F8D340;
            box-shadow: 0px 0px 10px rgba(51, 51, 51, 0.18);
            .faq-img {
                padding-top: 25px;
                img {
                    height: 60px;
                }
            }
            .faq-box {
                margin-top: 20px;
                .faq-answer {
                    display: block;
                    opacity: 1;
                    //padding-bottom: 30px;
                }
            }
        }
        .tvmall-en &:hover {
            .faq-img {
                padding-top: 4px;
            }
            .faq-box {
                margin-top: 0;
                .faq-question {
                    font-size: 15px;
                }
                .faq-answer{
                    font-size: 14px;
                    line-height: 1.4;
                    text-align: center;
                    padding: 0 5px;
                }
            }
        }
    }
}
.bform-wrapper {
    background: #324a5e;
    padding: 51px 16px 58px;
    .bf-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #F8D340;
    }
    .bform-wrap {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .bf-input-wrap {
        padding-top: 12px;
    }
    .bf-input-group {
        margin-top: 20px;
        display: flex;
    }
    .bf-input-ctrl {
        width: 269px;
        margin-right: 20px;
        &.bf-input-long {
            width: 100%;
        }
        .txt {
            width: 100%;
            min-height: 32px;
            padding: 0 12px;
            background: transparent;
            border: 0 none;
            border-left: 1.75px solid #465f72;
            border-bottom: 1.75px solid #465f72;
            letter-spacing: 0.5px;
            font-weight: bold;
            font-size: 15px;
            color: #fff;
            border-radius: 0!important;
            &::placeholder {
                color: #fafafa;
            }
        }
    }
    .select-ctrl {
        border: 0 none;
        border-left: 1.75px solid #465f72;
        border-bottom: 1.75px solid #465f72;
        background: transparent;
        min-height: 32px;
        .dropdown-menu {
            transform: translate3d(-2px, 30px, 0px) !important;
        }
        .form-control {
            min-height: 32px!important;
            font-size: 15px;
            height: auto!important;
            &::placeholder {
                color: #fafafa;
            }
        }
    }
    .bf-btn-wrap {
        .btn {
            width: 159px;
            height: 32px;
            line-height: 32px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 15px;
            &[disabled] {
                background: #465f72;
                cursor: not-allowed;
                color: #324a5e;
                opacity: 1;
            }
        }
    }
    
}
.footer-main-wrap .footer-text-main {
    margin-top: 24px;
}
// popup
#bookingModal {
    .modal-dialog {
        max-width: 368px;
        margin: 0 auto;
    }
    .modal-content {
        border-radius: 0;
        border: 0 none;
    }
    button.close {
        display: none;
    }
    .modal-body {
        padding: 0;
        .booking-wrap {
            .bm-header {
                background: #00c4b3;
                color: #fff;
                height: 48px;
                line-height: 48px;
                padding-left: 22px;
                font-size: 16px;
                font-weight: bold;
            }
            .bm-footer {
                padding: 0 20px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .btn {
                    width: 100px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 12px;
                    &[disabled] {
                        background: #e4e4e4;
                        cursor: not-allowed;
                        color: #fff;
                    }
                }
                .back {
                    cursor: pointer;
                    background: transparent;
                    border: 0 none;
                    color: #cdcdcd;
                    font-size: 12px;
                    font-weight: bold;
                }
            }
            .choose-calendar-wrap {
                .bm-calendar-wrap {
                    padding: 20px;
                }
                .bm-footer {
                    justify-content: flex-end;
                }
            }
            .choose-time-wrap {
                display: none;
            }
            .bm-time-wrap {
                padding: 20px;
                display: flex;
                justify-content: space-between;;
                flex-wrap: wrap;
                button {
                    width: 48%;
                    height: 36px;
                    color: #444;
                    border: 1px solid #444;
                    border-radius: 99em;
                    background: #fff;
                    font-size: 14px;
                    font-weight: bold;
                    outline: none;
                    margin-bottom: 14px;
                    cursor: pointer;
                    &:hover, &.active {
                        background: #00c4b3;
                        color: #fff;
                        border-color: #00c4b3;
                    }
                }
            }
            .choose-contact-wrap {
                display: none;
                .bm-contact {
                    padding: 20px;
                    .text-item {
                        margin-bottom: 16px;
                        input {
                            width: 100%;
                            border: 1px solid #e4e4e4;
                            height: 48px;
                            padding: 0 16px;
                            outline: none;
                            font-size: 16px;
                            color: #000;
                            font-weight: 600;
                            &:focus {
                                border-color: #00c4b3;
                            }
                            &::placeholder {
                                color: #999
                            }
                        }
                    }
                }
            }
        }
    }
}

#menuModal, #crowdfundingMenuModal {
    .booking-wrap {
        padding-top: 30px;
        .pop-box {
            padding: 20px 30px;
            .subp-title {
                font-size: 16px;
                color: #666;
                font-weight: bold;
            }
            .subp-flex-ctrl {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                .subp-vtrl {
                    width: 49%;
                    .form-control {
                        height: auto!important;
                    }
                    input {
                        width: 100%;
                        height: auto !important;
                        padding: 0 10px;
                        border: 1px solid #e7e7e7;
                        border-radius: 0;
                        font-weight: bold;
                        font-size: 15px;
                        min-height: 46px;
                        &::placeholder {
                            color: #999;
                        }
                    }
                }
            }
            .subp-btn {
                text-align: center;
                margin-top: 20px;
                .btn {
                    &[disabled] {
                        background: #e4e4e4;
                        cursor: not-allowed;
                        color: #fff;
                    }
                }
            }
        }
    }
}

#thanksModal {
    .modal-dialog {
        max-width: 478px;
        margin: 0 auto;
        padding: 0 16px;
    }
    .modal-content {
        background: #FFFFFF;
        border: 3px solid #00C4B3;
        box-sizing: border-box;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }
    button.close {
        display: none;
    }
    .modal-body {
        padding: 0;
        .booking-wrap {
            text-align: center;
            padding: 37px 16px 27px;
            .thanks-title {
                font-size: 32px;
                color: #2b4153;
            }
            .thanks-icon {
                margin-top: 20px;
                img {
                    width: 70px;
                }
            }
            .thanks-msg {
                margin-top: 20px;
                color: #333;
                font-size: 18px;
                font-weight: bold;
            }
            .thanks-btn-wrap {
                margin-top: 20px;
                .btn {
                    width: 162px;
                    height: 48px;
                }
            }
        }
    }
}

.tvmall-thanks-wrapper {
    padding-top: 64px; 
    background: get_prefix_url("imgs/tvmall/thanks.jpg") no-repeat center center; 
    background-size: cover;
    .thanks-info {
        margin-left: 50%;
        height: 590px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1280px) {
            height: 480px;
        }
        @media screen and (max-width: 1024px) {
            height: 380px;
        }
        .thanks-title {
            font-size: 42px;
            line-height: 45px;
            font-weight: 800;
            color: #324a5e;
        }
        .thanks-desc {
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;
            color: #444;
            margin-top: 4px;
        }
        @media screen and (max-width: 850px) {
            height: 300px;
            .thanks-title {
                font-size: 30px;
            }
            .thanks-desc {
                font-size: 18px;
            }
        }
    }
}
.tvmall-thanks-page .footer-pay {
    display: none;
}

.nav-cbf {
    background: get_prefix_url("imgs/b-menu/arrows-down.png") center right no-repeat;
    padding-right: 18px !important;
    position: relative;
    display: inline-block;
    margin-left: 22px;
    span {
        cursor: pointer;
        font-size: 14px;
    }
    .cbf-layer {
        box-shadow: 2px 2px 6px 2px rgba(0,0,0,.15);
        border: 1px solid #e4e4e4;
        background: #fff;
        border-radius: 8px;
        display: none;
        position: absolute;
        left: -10px;
        top: 30px;
        width: 200px;
        min-height: 80px;
        padding: 5px 0;
        &.zh {
            width: 176px;
        }
        a {
            display: block;
            text-align: center;
            padding: 5px 0;
            margin-right: 0;
            color: #000;
            margin-left: 0;
            &:hover {
                color: #00c4b3;
            }
        }
    }
}

.nav-our-business {
    background: rgba(0, 0, 0, 0) get_prefix_url("imgs/b-menu/arrows-down.png") center right no-repeat;
    padding-right: 18px !important;
    position: relative;
    display: inline-block;
    span {
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;
    }
    .our-services-menu {
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, .15);
        border: 1px solid #e4e4e4;
        background: #fff;
        border-radius: 8px;
        display: none;
        position: absolute;
        left: -15px;
        top:30px;
        width: 440px;
        min-height: 100px;
        text-align: left;
        .services-item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e4e4e4;
            cursor: pointer;
            &:last-child {
                border-bottom: 0 none;
            }
            .sl-img {
                width: 152px;
                padding-top: 10px;
            }
            .sl-detail {
                flex: 1;
                padding-left: 20px;
                .sl-title {
                    font-size: 16px;
                    font-weight: bold;
                    color: #324a5e;
                    margin: 0;
                }
                .sl-desc {
                    font-size: 14px;
                    color: #949494;
                    line-height: 1.2;
                    padding: 6px 0;
                }
                .sl-link {
                    color: #324a5e;
                    font-weight: bold;
                    font-size: 12px;
                }
            }
            &:hover {
                background: #fef9e5;
            }
        }
    }
}
.osb-layer {
    background: #fff;
    display: none;
    position: fixed;
    left: 0;
    top: 64px;
    width: 100%;
    min-height: 100px;
    text-align: left;
    padding: 10px 0 32px;
    a:hover {
        text-decoration: none;
    }
    .osm-wrap {
        display: flex;
        justify-content: space-between;
        max-width: 1248px;
        margin: 0 auto;
    }
    .osm-title {
        font-size: 12px;
        color: #333;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-bottom: 1px solid #333;
        padding: 4px 0;
        a {
            color: #333;
            &:hover {
                color: #00c4b3;
            }
        }
    }
    .os-business-box {
        flex: 1;
        margin-right: 16px;
        background: #f6f6f6;
        padding: 10px 24px;
    }
    .osm-b-wrap {
        display: flex;
        justify-content: space-between;
        .osm-b-box {
            border: 1px solid #e7e7e7;
            border-radius: 10px;
            padding: 22px 15px 12px;
            margin-top: 15px;
            display: block;
            max-width: 272px;
            flex: 1;
            &:nth-child(2) {
                margin: 15px 16px 0;
            }
            &:hover {
                background: #fff;
            }
            .osm-top {
                .osm-box-title {
                    font-size: 18px;
                    font-weight: bold;
                    color: #324a5e;
                }
                .osm-box-info {
                    font-size: 13px;
                    color: #949494;
                    margin-top: 4px;
                    min-height: 38px;
                }
            }
            .osm-bottom {
                background: #fff;
                margin-top: 20px;
                text-align: center;
                img {
                    height: 200px;
                }
            }
        }
    }
    
    .osm-others {
        width: 32%;
        max-width: 400px;
    }
    .osm-valet {
        background: #e9f8f7;
        padding: 10px 24px;
    }
    .osm-bus-moving {
        background: #fafafa;
        padding: 10px 22px;
        margin-top: 16px;
    }
    .osm-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 0 0;
        .osm-left {
            max-width: 200px;
            .osm-box-title {
                font-size: 18px;
                font-weight: bold;
                color: #324a5e;
            }
            .osm-box-info {
                font-size: 13px;
                color: #949494;
                margin-top: 4px;
            }
        }
        .osm-right {
            img {
                background: #fff;
                height: 63px;
            }
        }
    }
    .btn-menu-checkout {
        width: 151px;
        height: 32px;
        line-height: 32px;
        border: 0 none;
        background: #f8d340;
        text-align: center;
        font-size: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: bold;
        color: #444;
        display: block;
        &:hover {
            background: #bf9f1f;
            color: #fff;
        }
    }
    .btn-menu-see {
        width: 151px;
        height: 32px;
        line-height: 32px;
        border: 1px solid #324a5e;
        background: transparent;
        text-align: center;
        font-size: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: bold;
        color: #324a5e;
        display: block;
        &:hover {
            background: #324a5e;
            color: #fff;
        }
    }
    .osm-door-to-door {
        width: 32%;
        max-width: 400px;
        background: #e9f8f7;
        padding: 10px 22px;
        .osm-item-wrap {
            border: 1px solid #d1dfde;
            border-radius: 10px;
            padding: 22px 15px;
            margin-top: 15px;
        }
        .osm-xl-wrap {
            border: 1px solid #d1dfde;
            border-radius: 10px;
            padding: 22px 15px;
            margin-top: 16px;
        }
        .osm-box-link {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
        }
    }
    .osm-business {
        background: #e9f8f7;
        padding: 10px 22px;
        .osm-fulfillment-wrap {
            border: 1px solid #d1dfde;
            border-radius: 10px;
            padding: 22px 15px 12px;
            margin-top: 15px;
            display: block;
            .osm-box-link .link-detail {
                color: #00c4b3;
            }
            &:hover {
                background: #fff;
                .osm-box-link .link-detail {
                    text-decoration: underline;
                }
            }
        }
    }
    .osm-valet-wrap {
        .osm-box {
            border-bottom: 1px solid #d1dfde;
        }
    }
    .osm-cm {
        display: flex;
        padding: 16px 0 0;
        justify-content: space-between;
        .os-cs-name {
            font-size: 18px;
            font-weight: bold;
            color: #324a5e;
            .link-name {
                color: #324a5e;
                &:hover {
                    text-decoration: underline;
                }
            }
            .link-detail {
                font-size: 18px;
                font-weight: bold;
                color: #00c4b3;
                
                
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.osb-layer  .new-oms-wrap {
    max-width: 1248px;
    margin: 0 auto;
    .osm-title {
        font-size: 12px;
        color: #333;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        border-bottom: 1px solid #333;
        padding: 4px 0;
        a {
            color: #333;
            &:hover {
                color: #00c4b3;
            }
        }
    }
    .oms-box {
        padding: 0 24px;
    }
    .oms-fulfillment {
        background: #f3f3f3;
        padding: 12px 24px 0;
    }
    .osm-f-wrap {
        padding: 15px 0 0;
        display: flex;
        justify-content: space-between;
        a.omsf-item {
            display: block;
            text-align: center;
            padding: 13px 30px 30px;
            border: 1px solid #e7e7e7;
            background: #fff;
            border-radius: 10px;
            width: 24%;
            transition: box-shadow .2s;
            @media screen and (max-width: 1080px) {
                padding: 13px 16px 20px;;
            }
            &:hover {
                box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.15);
            }
            .of-title {
                font-size: 18px;
                line-height: 1.4;
                color: #324a5e;
                font-weight: bold;
                @media screen and (max-width: 1080px) {
                    font-size: 16px;
                }
                span {
                    font-size: 18px;
                    @media screen and (max-width: 1170px) {
                        display: none;
                    }
                }
            }
            .of-desc {
                margin-top: 3px;
                font-size: 15px;
                line-height: 1.2;
                font-weight: 500;
                color: #999;
                min-height: 54px;
                @media screen and (max-width: 1080px) {
                    min-height: 76px;
                }
            }
            .of-img {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 75px;
                margin-top: 20px;
                @media screen and (max-width: 1080px) {
                    height: 55px;
                }
                img {
                    max-height: 100%;
                }
                &.of-more-img {
                    justify-content: space-around;
                }
            }
        }
        
    }
    .oms-new-flex {
        display: flex;
        justify-content: space-between;
        background: #f3f3f3;
        padding: 16px 24px;
        .oms-box {
            width: 49.4%;
            padding: 0;
        }
        .osm-new-normal-box {
            display: flex;
            justify-content: space-between;
            padding: 15px 0;
            .osm-normal-item {
                width: 49%;
                text-align: left;
                border: 1px solid #e7e7e7;
                background: #fff;
                border-radius: 10px;
                transition: box-shadow .2s;
                padding: 13px 24px 16px;
                display: block;
                @media screen and (max-width: 1080px) {
                    padding: 13px 16px 16px;
                }
                &:hover {
                    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.15);
                }
                .osm-normal-title {
                    font-size: 18px;
                    line-height: 1.4;
                    color: #324a5e;
                    font-weight: bold;
                    @media screen and (max-width: 1080px) {
                        font-size: 16px;
                    }
                }
                .osm-normal-desc {
                    margin-top: 3px;
                    font-size: 15px;
                    line-height: 1.2;
                    font-weight: 500;
                    color: #999;
                    min-height: 54px;
                }
            }
        }
    }
}
@media screen and (max-width: 1310px) {
    .logistics-logo img {
        height: 30px;
        &.desktop-logo {
            display: none;
        }
        &.mobile-logo {
            display: block;
        }
    }
}
@media screen and (max-width: 1190px) {
    .osb-layer {
        .osm-business, .os-business-box, .osm-bus-moving {
            padding: 10px;
        }
    }
}

@media screen and (max-width: 1150px) {
    .osb-layer .osm-b-wrap .osm-b-box .osm-bottom img {
        height: 180px;
    }
    .osb-layer .osm-b-wrap .osm-b-box .osm-top .osm-box-info {
        min-height: 57px;
    }
}
@media screen and (max-width: 1130px) {
    .header-bar {
        padding: 0 16px;
    }
}

@media screen and (max-width: 1080px) {
    .osb-layer .osm-b-wrap .osm-b-box .osm-bottom img {
        height: 160px;
    }
    .osb-layer .osm-b-wrap .osm-b-box .osm-top .osm-box-title {
        font-size: 16px;
    }
    .osb-layer .osm-box .osm-left .osm-box-title {
        font-size: 16px;
    }
    .osb-layer .osm-cm .os-cs-name .link-detail {
        font-size: 16px;
    }
    .logistics-nav a.navl {
        font-size: 12px;
        margin-left: 14px;
    }
    .nav-cbf {
        margin-left: 14px;
    }
    .logistics-nav a.nav_tel {
        font-size: 14px;
    }
    .logistics-nav a.btn {
        height: 28px;
        line-height: 28px;
        margin-left: 14px;
        width: 155px;
    }
}

@media screen and (max-width: 1000px) {
    .osb-layer {
        padding: 0 24px 24px;
        .osm-wrap {
            flex-direction: column;
        }
        .os-business-box{
            margin-right: 0;
        }
        .osm-others {
            width: 100%;
            max-width: 100%;
            margin-top: 16px;
        }
        .osm-business, .osm-bus-moving {
            padding: 10px 22px;
        }
        .osm-box {
            justify-content: flex-start;
        }
        .osm-box .osm-left {
            order: 2;
        }
        .osm-box .osm-right {
            order: 1;
            margin-right: 20px;
        }
        .osm-locker-wrap {
            display: flex;
            justify-content: space-between;
            background: #fffdf3;
            border-radius: 0;
            border: 0 none;
            padding: 22px 15px 10px;
            .osm-locker-base {
                width: 49%;
            }
            .wwd-pos {
                background: transparent;
                width: 49%;
                padding: 0;
            }
        }
        .osm-business .osm-fr-wrap {
            display: flex;
            justify-content: space-between;
            .osm-fulfillment-wrap {
                width: 49%;
                padding: 12px 15px;
            }
        }
        .osm-bus-moving {
            margin-top: 16px;
        }
    }
    .header-bar {
        padding: 0 16px;
    }
    
}

@media screen and (max-width: 979px) {

    .logistics-nav a.navl {
        margin-left: 14px;
    }
    .logistics-nav a.btn {
        width: 120px;
        letter-spacing: 0px;
        font-size: 12px;
    }
    .tvmall-banner-wrapper .main-info-wrap .main-left .main-title {
        font-size: 24px;
        .tvmall-en & {
            line-height: 28px;
        }
    }
    .tvmall-banner-wrapper .main-info-wrap .main-left .main-desc {
        font-size: 16px;
        .tvmall-en & {
            line-height: 22px;
        }
    }
    .tvmall-banner-wrapper .main-info-wrap .main-left .main-link a {
        font-size: 18px;
    }
    .tvmall-banner-wrapper .main-info-wrap .main-right {
        width: 380px;
        margin-left: 30px;
        .main-form-wrap {
            padding: 28px 16px 24px;
            .mfw-title {
                font-size: 22px;
            }
            .txt {
                width: 170px;
            }
            .select-ctrl {
                width: 170px;
            }
        }
    }
    .solution-wrapper .solu-item .solu-info .fn-box .fn-title {
        font-size: 18px;
    }
    .solution-wrapper .solu-item .solu-info .fn-box .fn-content {
        font-size: 16px;
        line-height: 23px;
        margin-top: 8px;
    }
    .solution-wrapper .solu-item .solu-info .fbs-title {
        font-size: 24px;
        line-height: 30px;
        margin-top: 26px;
    }
    .solution-wrapper .solu-item .solu-info .fbs-content {
        font-size: 18px;
        line-height: 24px;
        margin-top: 12px;
    }
    .solution-wrapper .solu-item .solu-img img {
        width: 260px;
        .tvmall-en & {
            width: 220px;
        }
    }
    .tvmall-en .solution-wrapper .solu-item .solu-info {
        .fannao {
            padding-left: 16px;
        }
        .fn-box {
            padding: 20px 16px 20px 16px;
            margin-top: 8px;
            .fn-title {
                font-size: 14px;
            }
            .fn-content {
                font-size: 14px;
                line-height: 16px;
            }
            .fn-icon {
                width: 60px;
                height: 60px;
                top: -34px;
            }
        }
        .fbs-title {
            padding-left: 16px;
            font-size: 20px;
            line-height: 24px;
            margin-top: 10px;
        }
        .fbs-content {
            padding-left: 16px;
            font-size: 16px;
            line-height: 20px;
            margin-top: 8px;
        }
    }
    div.home-title h2 {
        font-size: 28px;
        .tvmall-en & {
            font-size: 26px;
        }
    }
    .faq-wrapper .faq-tag {
        width: 164px;
        font-size: 16px;
    }
    .faq-wrapper .faq .faq-box .faq-question {
        font-size: 16px;
    }
    .faq-wrapper .faq .faq-box .faq-answer {
        font-size: 14px;
    }
    .faq-wrapper .faq:hover .faq-box, .faq-wrapper .faq.cur .faq-box {
        margin-top: 10px;
    }
    .faq-wrapper .faq:hover .faq-img, .faq-wrapper .faq.cur .faq-img {
        padding-top: 8px;
    }
    .tvmall-en .faq-wrapper .faq-group {
        flex-direction: column;
    }
    .tvmall-en .faq-wrapper .faq-tag {
        border-radius: 10px;
        width: 300px;
    }
    .tvmall-en .faq-wrapper .faq:hover .faq-box .faq-answer {
        line-height: 1.2;
    }
}

@media screen and (max-width: 900px) {
    .header-bar {
        height: 50px;
    }
    .header-bar .logistics-logo img {
        height: 30px;
    }
    .logistics-nav {
        display: none;
    }
    .os-mobile-wrap {
        display: flex;
        align-items: center;
        .mobile-os {
            margin-right: 16px;
            a {
                font-size: 13px;
                font-weight: bold;
                text-transform: uppercase;
                color: #00c4b3;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    #ourServicesMenu {
        background: #fff;
        .modal-dialog {
            margin: 0;
            transform: none;
            max-width: inherit;
        }
        .modal-content {
            border-radius: 0;
            border: 0 none;
        }
        .modal-body {
            padding: 0;
            .close {
                position: fixed;
                right: 20px;
                top: 7px;
                cursor: pointer;
                color: #999;
                font-size: 30px;
                width: 30px;
                line-height: 1;
                font-weight: 500;
                z-index: 99;
            }
        }
        .our-business-popup {
            text-align: left;
            padding-bottom: 16px;
            .os-title {
                color: #999;
                font-size: 14px;
                font-weight: bold;
                height: 48px;
                line-height: 48px;
                padding-left: 24px;
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                background: #fff;
                z-index: 2;
                &.no-top {
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
                }
            }
            .os-pupup-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-weight: bold;
                padding-top: 13px;
                .os-name {
                    color: #324a5e;
                    font-size: 16px;
                    a {
                        color: #324a5e;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .os-type {
                    display: inline-block;
                    padding: 0 8px;
                    height: 22px;
                    line-height: 22px;
                    font-size: 10px;
                    color: #333;
                    background: #fef5d8;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
            }
            .os-top-padding {
                padding: 0 8px 16px;
                .os-pupup-box .os-name a {
                    color: #324a5e;
                    span {
                        display: none;
                    }
                }
                .os-pupup-box .os-type {
                    background: #f3f3f3;
                }
                .os-m-desc {
                    margin-top: 8px;
                    font-size: 14px;
                    color: #999;
                    line-height: 17px;
                }
                .osb-line {
                    border-top: 1px solid #f3f3f3;
                    margin-top: 16px;
                }
                .os-tim {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-top: 8px;
                    .osb-info {
                        font-size: 14px;
                        color: #949494;
                        line-height: 1.14;
                        max-width: 150px;
                    }
                    .osb-img {
                        width: 80px;
                        background: #fff;
                        text-align: center;
                        img {
                            max-width: 80px;
                        }
                    }
                }
            }
            .os-locker-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 13px;
                &.has-bt-space {
                    padding-bottom: 16px;
                }
                .os-desc {
                    font-size: 14px;
                    color: #6a6a6a;
                    font-style: italic;
                }
                .os-detail a {
                    font-size: 14px;
                    font-weight: bold;
                    span {
                        transition: transform .2s;
                        display: inline-block;
                    }
                }
            }
            .os-valet-desc {
                color: #6a6a6a;
                font-size: 14px;
                margin-top: 7px;
            }
            .os-locker-info {
                .btn {
                    width: 120px !important;
                    height: 30px !important;
                    padding: 0 !important;
                    font-style: normal;
                    font-size: 12px!important;
                    line-height: 32px;
                    color: #324a5e;
                    border-radius: 0!important;
                    border: 1px solid #324a5e;
                    background: transparent;
                    text-transform: uppercase;
                }
                .btn-y {
                    width: 136px !important;
                    height: 32px !important;
                    padding: 0 !important;
                    font-style: normal;
                    font-size: 12px;
                    line-height: 32px;
                    color: #333;
                    text-transform: uppercase;
                    background: #f8d340;
                    display: block;
                    text-align: center;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
            .os-top-info {
                background: #fafafa;
                padding: 0 8px 8px;
                margin: 48px 8px 0;
            }
            .os-bottom-info {
                margin: 16px 8px 0;
                padding: 0 16px 24px;
                background: #e9f8f7;
                
                .item-sep {
                    border-bottom: 1px solid #d3ebea;
                }
                .os-type {
                    background: #d3ebea;
                }
            }
            .os-other-title {
                margin-top: 20px;
                border-bottom: 1px solid #333;
                font-size: 10px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                padding-bottom: 3px;
            }
            .os-others-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 14px;
                .os-other-name {
                    font-size: 16px;
                    font-weight: bold;
                    color: #324a5e;
                    a {
                        color: #324a5e;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .os-other-detail a {
                    font-size: 12px;
                    font-weight: bold;
                }
            }
            .os-business-info {
                margin: 8px 8px 24px;
                background: #fafafa;
                padding: 0 16px;
                .os-name a {
                    color: #00c4b3;
                }
                .os-type {
                    background: #f3f3f3;
                }
                .os-valet-desc {
                    padding-bottom: 14px;
                    border-bottom: 1px solid #f3f3f3;
                }
                .os-last {
                    border-top: 1px solid #f3f3f3;
                    padding-bottom: 20px;
                    margin-top: 13px;
                }
            }
        }
    }
    /* side menu */
    .logistics-nav-bar {
        display: block;
    }
    .logistics_line {
        height: 3px;
        background: #000;
        width: 25px;
        margin: 5px 0;
        position: relative;
        transition: transform .5s;
    }
    div.line-t {
        transform: rotate(45deg);
    }
    div.line-b {
        transform: rotate(-45deg);
    }
    .logistics-shadow {
        width: 100%;
        height: calc(100vh - 50px);
        background: rgba(0, 0, 0, .5);
        position: fixed;
        left: 0;
        top: 50px;
        z-index: 10;
    }
    .sider-bar-logistics {
        width: 100%;
        background: #fff;
        position: fixed;
        left: 0;
        top: 50px;
        padding: 10px 20px;
        z-index: 12;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px 0px;
    }
    .sider-bar-logistics a {
        color: #333;
        display: block;
        margin: 10px 0;
        font-size: 16px;
        font-weight: bold;
        &.btn-get-price {
            width: 100%!important;
            font-size: 14px;
            padding: 10px 0 !important;
            margin-top: 10px;
            text-transform: uppercase;
            height: auto!important;
            line-height: inherit!important;
        }
    }
    
}

@media screen and (max-width: 767px) {
    .header-bar {
        top: 40px;
    }
    .logistics-shadow {
        top: 90px;
    }
    .sider-bar-logistics {
        top: 90px;
    }
    .modal-content div.home-title h2 {
        font-size: 18px;
    }
    #menuModal .booking-wrap .pop-box .subp-flex-ctrl,
    #crowdfundingMenuModal .booking-wrap .pop-box .subp-flex-ctrl {
        flex-direction: column;
        margin-top: 0;
        .subp-vtrl {
            width: 100%;
            margin-top: 10px;
        }
    }
    #menuModal .booking-wrap .pop-box .subp-btn .btn,
    #crowdfundingMenuModal .booking-wrap .pop-box .subp-btn .btn {
        width: 100%!important;
    }
    .mobile-logo {
        display: block;
    }
    .desktop-logo {
        display: none;
    }
    
    .footer-main-wrap .foot-logo-wrap img {
        height: 45px;
    }
    div.home-title h2 {
        font-size: 22px;
        .tvmall-en & {
            font-size: 22px;
        }
    }
    .tvmall-en .for-title {
        max-width: 85%;
        margin: 0 auto;
    }
    .tvmall-en .tvmall-banner-wrapper .main-info-wrap .main-right .main-form-wrap .mfw-title {
        font-size: 18px;
    }
    .tvmall-banner-wrapper {
        padding-top: 90px;
        display: flex;
        flex-direction: column;
        .main-info-wrap {
            order: 2;
            .main-info {
                padding: 19px 0;
                flex-direction: column;
            }
            .main-left {
                .main-desc {
                    font-size: 18px;
                    margin-top: 16px;
                }
                .main-link {
                    margin-top: 6px;
                    a {
                        font-size: 18px;
                        line-height: 22px;
                        border: 0 none;
                        padding: 0;
                        display: inline;
                        text-decoration: underline;
                        i.fas {
                            display: none;
                        }
                    }    
                }
            }
            .main-right {
                width: 100%;
                margin-left: 0;
                margin-top: 18px;
                .main-form-wrap {
                    position: static;
                    border-radius: 25px;
                    padding: 26px 16px 32px;
                    .input-group {
                        margin-bottom: 0;
                        flex-direction: column;
                    }
                    .default-hide-in-mobile {
                        display: none;
                    }
                    .input-ctrl {
                        width: 100%;
                        margin-top: 14px;
                    }
                    .txt {
                        width: 100%;
                    }
                    .button-wrap {
                        position: relative;
                        .btn {
                            width: 158px !important;
                            height: 48px !important;
                            font-size: 15px;
                            letter-spacing: 1px;
                        }
                        .see-less {
                            display: none;
                            font-size: 14px;
                            color: #fff;
                            text-decoration: underline;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .solution-wrapper {
        padding: 36px 0 40px;
        background: #f6f5f5;
        .solution-wrap {
            padding: 35px 16px 0;
        }
        .solu-item {
            flex-direction: column;
            margin-bottom: 20px;
            border: 1px solid #d8d8d8;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 25px;
            background: #fff;
            padding: 16px 12px 30px;
            .solu-img {
                order: 2;
                width: 100%;
                margin-top: 30px;
                img {
                    width: 206px;
                }
            }
            .solu-info {
                margin: 0;
                width: 100%;
                order: 1;
                .fannao {
                    font-size: 14px;
                    line-height: 23px;
                    padding-left: 14px;
                    .tvmall-en & {
                        font-size: 12px;
                    }
                }
                .fn-box {
                    padding: 11px 9px 12px 14px;
                    margin-top: 4px;
                    .fn-title {
                        font-size: 16px;
                        line-height: 19px;
                    }
                    .fn-content {
                        font-size: 15px;
                        line-height: 21px;
                        margin-top: 7px;
                    }
                    .fn-icon {
                        width: 60px;
                        height: 60px;
                        top: -30px;
                        .tvmall-en & {
                            width: 40px;
                            height: 40px;
                            top: -22px;
                        }
                    }
                }
                .fbs-title {
                    font-size: 20px;
                    line-height: 24px;
                    padding-left: 14px;
                    margin-top: 24px;
                    .tvmall-en & {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                .fbs-content {
                    font-size: 16px;
                    line-height: 23px;
                    padding-left: 14px;
                    margin-top: 12px;
                    .tvmall-en & {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
            &.second-soul-item {
                .solu-info {
                    margin: 0 0 0;
                }
            }
        }
        .solution-btn-wrap {
            text-align: center;
            margin-top: 30px;
            .btn {
                width: 256px!important;
            }
        }
    }
    .faq-wrapper {
        background: #fff;
        padding: 54px 16px 70px;
        .faq-wrap {
            margin-top: 30px;
        }
        .faq-group {
            flex-direction: column;
            display: block;
        }
        .faq-list {
            width: 100%;
            flex-direction: column;
        }
        .faq-tag {
            display: none;
        }
        .faq-item {
            width: 100%;
            display: block;
            height: auto;
        }
        .faq {
            border: 0 none;
            box-shadow: none;
            border-radius: 0;
            width: 100%;
            height: auto;
            transition: height .2s;
            display: flex;
            align-items: flex-start;
            padding: 25px 0 18px;
            border-bottom: 1px dashed #8E969D;
            .faq-img {
                text-align: left;
                padding-top: 0;
                img {
                    height: 50px;
                    width: 80px;
                }
            }
            .faq-box {
                margin-top: 0;
                padding: 0 0 0 0;
                width: 100%;
                .faq-question {
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 19px;
                    text-align: left;
                    color: #444;
                    padding-right: 48px;
                    background: get_prefix_url("imgs/tvmall/ar.png") no-repeat 95% center;
                }
                .faq-answer {
                    font-size: 14px;
                    padding-right: 48px;
                    text-align: left;
                    color: #444;
                    margin-top: 16px;
                }
            }
            &:hover, &.cur {
                height: auto;
                border: 0 none;
                border-bottom: 1px dashed #8E969D;
                box-shadow: none;
                .faq-img {
                    padding-top: 0;
                    img {
                        height: 80px;
                        height: 50px;
                    }
                }
                .faq-box {
                    margin-top: 0;
                    .faq-answer {
                        display: block;
                    }
                }
            }
            &.last-one-item {
                &:hover, &.cur {
                    border-bottom: 0 none;
                }
            }
        }
    }
    .bform-wrapper {
        padding: 25px 16px 38px;
        .bf-title {
            font-size: 22px;
            .tvmall-en & {
                font-size: 18px;
            }
        }
        .bform-wrap {
            flex-direction: column;
            align-items: center;
        }
        .bf-input-ctrl {
            width: 50%;
            margin-right: 0;
            &:first-child {
                margin-right: 13px;
            }
            .txt {
                padding: 0 8px;
                font-size: 14px;
            }
        }
        .select-ctrl {
            .dropdown-menu {
                width: 100%;
            }
        }
        .bf-btn-wrap {
            margin-top: 30px;
            .btn {
                width: 159px !important;
                height: 32px !important;
                line-height: 32px;
                padding: 0 !important;
            }
        }
        
    }
    .tvmall-en .faq-wrapper .faq:hover .faq-box .faq-answer {
        padding: 0 48px 0 0;
        text-align: left;
    }
    #bookingModal .modal-body .booking-wrap .bm-footer .btn {
        width: 100px!important;
        height: 32px!important;
        line-height: 32px !important;
        padding: 0!important;
    }
    .tvmall-thanks-wrapper {
        padding-top: 54px; 
        background: get_prefix_url("imgs/tvmall/thank_m.jpg") no-repeat center bottom; 
        background-size: cover;
        .thanks-info {
            text-align: center;
            height: 500px;
            margin: 0;
            width: 100%;
            padding: 40px 16px;
            justify-content: center;
            align-items: flex-start;
        }
    }
    
}

@media screen and (max-width: 370px) {
    .tvmall-thanks-wrapper {
        .thanks-info {
            .thanks-title {
                font-size: 26px;
            }
            .thanks-desc {
                font-size: 16px;
            }
        }
    }
}



